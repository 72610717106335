import React, { useState, useEffect } from 'react';
import axios from 'axios';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    TableSortLabel,
    Box,
    CircularProgress,
    ToggleButtonGroup,
    ToggleButton,
    Button
} from '@mui/material';
import { DropdownDate } from './selecteur';
import './css/ranking.css';
import linkedinIcon from '../img/icons/linkedinIcon.png';
import exportExcelIcon from '../img/exportExcel.png';
import * as XLSX from 'xlsx';

const apiUrl = process.env.REACT_APP_BACKEND_URL;

const Stats = () => {
    const [stats, setStats] = useState([]);
    const [loading, setLoading] = useState(true);
    const [orderBy, setOrderBy] = useState('views');
    const [order, setOrder] = useState('desc');
    const [selectedMonth, setSelectedMonth] = useState(new Date().getMonth() + 1);
    const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
    const [selectedEntity, setSelectedEntity] = useState('All');
    const [entities, setEntities] = useState(['All']);
    const [viewType, setViewType] = useState('users'); // 'users' ou 'entities'

    // Generate years for filter (from 2023 to current year)
    const years = Array.from(
        { length: new Date().getFullYear() - 2023 }, 
        (_, i) => ({ value: 2024 + i, label: (2024 + i).toString() })
    );
    
    const months = [
        { value: 1, label: 'January' },
        { value: 2, label: 'February' },
        { value: 3, label: 'March' },
        { value: 4, label: 'April' },
        { value: 5, label: 'May' },
        { value: 6, label: 'June' },
        { value: 7, label: 'July' },
        { value: 8, label: 'August' },
        { value: 9, label: 'September' },
        { value: 10, label: 'October' },
        { value: 11, label: 'November' },
        { value: 12, label: 'December' }
    ];

    useEffect(() => {
        fetchStats();
    }, [selectedMonth, selectedYear, selectedEntity, viewType]);

    const fetchStats = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${apiUrl}/api/users-stats`, {
                params: {
                    month: selectedMonth,
                    year: selectedYear,
                    entity: selectedEntity,
                    viewType: viewType
                },
                withCredentials: true
            });
            setStats(response.data.stats);
            if (response.data.entities) {
                setEntities(response.data.entities);
            }
        } catch (error) {
            console.error('Error fetching statistics:', error);
        } finally {
            setLoading(false);
        }
    };

    const handleSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleViewTypeChange = (event, newViewType) => {
        if (newViewType !== null) {
            setViewType(newViewType);
        }
    };

    const sortedStats = [...stats].sort((a, b) => {
        const isAsc = order === 'asc';
        switch (orderBy) {
            case 'name':
                return isAsc 
                    ? `${a.givenName} ${a.familyName}`.localeCompare(`${b.givenName} ${b.familyName}`)
                    : `${b.givenName} ${b.familyName}`.localeCompare(`${a.givenName} ${a.familyName}`);
            case 'posts':
                return isAsc 
                    ? (viewType === 'entities' ? a.postsCount - b.postsCount : a.postsCount - b.postsCount)
                    : (viewType === 'entities' ? b.postsCount - a.postsCount : b.postsCount - a.postsCount);
            case 'views':
                return isAsc 
                    ? (viewType === 'entities' ? a.totalViews - b.totalViews : a.totalViews - b.totalViews)
                    : (viewType === 'entities' ? b.totalViews - a.totalViews : b.totalViews - a.totalViews);
            case 'impressionsPerUser':
                const aImpPerUser = a.userCount ? a.totalViews / a.userCount : 0;
                const bImpPerUser = b.userCount ? b.totalViews / b.userCount : 0;
                return isAsc ? aImpPerUser - bImpPerUser : bImpPerUser - aImpPerUser;
            case 'scorePerUser':
                const aScorePerUser = a.userCount ? a.score / a.userCount : 0;
                const bScorePerUser = b.userCount ? b.score / b.userCount : 0;
                return isAsc ? aScorePerUser - bScorePerUser : bScorePerUser - aScorePerUser;
            case 'avgViews':
                const aAvgViews = a.postsCount ? a.totalViews / a.postsCount : 0;
                const bAvgViews = b.postsCount ? b.totalViews / b.postsCount : 0;
                return isAsc ? aAvgViews - bAvgViews : bAvgViews - aAvgViews;
            case 'job':
                return isAsc ? (a.job || '').localeCompare(b.job || '') : (b.job || '').localeCompare(a.job || '');
            case 'entity':
                return isAsc 
                    ? (viewType === 'entities' ? a.location.localeCompare(b.location) : (a.location || '').localeCompare(b.location || ''))
                    : (viewType === 'entities' ? b.location.localeCompare(a.location) : (b.location || '').localeCompare(a.location || ''));
            case 'score':
                return isAsc 
                    ? (viewType === 'entities' ? a.score - b.score : a.score - b.score)
                    : (viewType === 'entities' ? b.score - a.score : b.score - a.score);
            case 'userCount':
                return isAsc ? a.userCount - b.userCount : b.userCount - a.userCount;
            case 'subscribers':
                return isAsc ? a.subscribers - b.subscribers : b.subscribers - a.subscribers;
            case 'subscribersWon':
                return isAsc ? a.subscribersWon - b.subscribersWon : b.subscribersWon - a.subscribersWon;
            default:
                return 0;
        }
    });

    // Convertir les entités en format pour le dropdown
    const entityOptions = entities.map(entity => ({
        value: entity,
        label: entity === 'All' ? 'All Entities' : entity
    }));

    const renderTableHeaders = () => {
        if (viewType === 'entities') {
            return (
                <TableRow>
                    <TableCell>
                        <TableSortLabel
                            active={orderBy === 'entity'}
                            direction={orderBy === 'entity' ? order : 'asc'}
                            onClick={() => handleSort('entity')}
                        >
                            Entity
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>
                        <TableSortLabel
                            active={orderBy === 'userCount'}
                            direction={orderBy === 'userCount' ? order : 'asc'}
                            onClick={() => handleSort('userCount')}
                        >
                            Users
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>
                        <TableSortLabel
                            active={orderBy === 'posts'}
                            direction={orderBy === 'posts' ? order : 'asc'}
                            onClick={() => handleSort('posts')}
                        >
                            Total Posts
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>
                        <TableSortLabel
                            active={orderBy === 'views'}
                            direction={orderBy === 'views' ? order : 'asc'}
                            onClick={() => handleSort('views')}
                        >
                            Total Impressions
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>
                        <TableSortLabel
                            active={orderBy === 'score'}
                            direction={orderBy === 'score' ? order : 'asc'}
                            onClick={() => handleSort('score')}
                        >
                            Total Score
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>
                        <TableSortLabel
                            active={orderBy === 'impressionsPerUser'}
                            direction={orderBy === 'impressionsPerUser' ? order : 'asc'}
                            onClick={() => handleSort('impressionsPerUser')}
                        >
                            Moy. Impressions/User
                        </TableSortLabel>
                    </TableCell>
                    <TableCell>
                        <TableSortLabel
                            active={orderBy === 'scorePerUser'}
                            direction={orderBy === 'scorePerUser' ? order : 'asc'}
                            onClick={() => handleSort('scorePerUser')}
                        >
                            Moy. Score/User
                        </TableSortLabel>
                    </TableCell>
                </TableRow>
            );
        }

        return (
            <TableRow>
                <TableCell>
                    <TableSortLabel
                        active={orderBy === 'name'}
                        direction={orderBy === 'name' ? order : 'asc'}
                        onClick={() => handleSort('name')}
                    >
                        Name
                    </TableSortLabel>
                </TableCell>
                <TableCell className='text-center'>Profile</TableCell>
                <TableCell className='text-center'>Connected</TableCell>
                <TableCell>
                    <TableSortLabel
                        active={orderBy === 'job'}
                        direction={orderBy === 'job' ? order : 'asc'}
                        onClick={() => handleSort('job')}
                    >
                        Job
                    </TableSortLabel>
                </TableCell>
                <TableCell>
                    <TableSortLabel
                        active={orderBy === 'entity'}
                        direction={orderBy === 'entity' ? order : 'asc'}
                        onClick={() => handleSort('entity')}
                    >
                        Entity
                    </TableSortLabel>
                </TableCell>
                <TableCell>
                    <TableSortLabel
                        active={orderBy === 'subscribers'}
                        direction={orderBy === 'subscribers' ? order : 'asc'}
                        onClick={() => handleSort('subscribers')}
                    >
                        Followers
                    </TableSortLabel>
                </TableCell>
                <TableCell>
                    <TableSortLabel
                        active={orderBy === 'subscribersWon'}
                        direction={orderBy === 'subscribersWon' ? order : 'asc'}
                        onClick={() => handleSort('subscribersWon')}
                    >
                        Followers Won
                    </TableSortLabel>
                </TableCell>
                <TableCell>
                    <TableSortLabel
                        active={orderBy === 'posts'}
                        direction={orderBy === 'posts' ? order : 'asc'}
                        onClick={() => handleSort('posts')}
                    >
                        Posts
                    </TableSortLabel>
                </TableCell>
                <TableCell>
                    <TableSortLabel
                        active={orderBy === 'views'}
                        direction={orderBy === 'views' ? order : 'asc'}
                        onClick={() => handleSort('views')}
                    >
                        Impressions
                    </TableSortLabel>
                </TableCell>
                <TableCell>
                    <TableSortLabel
                        active={orderBy === 'avgViews'}
                        direction={orderBy === 'avgViews' ? order : 'asc'}
                        onClick={() => handleSort('avgViews')}
                    >
                        Moy. Impressions/Post
                    </TableSortLabel>
                </TableCell>
                <TableCell>
                    <TableSortLabel
                        active={orderBy === 'score'}
                        direction={orderBy === 'score' ? order : 'asc'}
                        onClick={() => handleSort('score')}
                    >
                        Score
                    </TableSortLabel>
                </TableCell>
            </TableRow>
        );
    };

    const renderTableRows = () => {
        // Fonction pour formater les nombres avec des espaces entre les milliers
        const formatNumber = (number) => {
            return new Intl.NumberFormat('fr-FR').format(number);
        };

        if (viewType === 'entities') {
            return sortedStats.map((entity) => (
                <TableRow key={entity.location}>
                    <TableCell>{entity.location}</TableCell>
                    <TableCell>{entity.userCount}</TableCell>
                    <TableCell>{entity.postsCount}</TableCell>
                    <TableCell>{formatNumber(entity.totalViews)}</TableCell>
                    <TableCell>{formatNumber(entity.score || 0)}</TableCell>
                    <TableCell>{entity.userCount > 0 ? formatNumber(Math.round(entity.totalViews / entity.userCount)) : 0}</TableCell>
                    <TableCell>{entity.userCount > 0 ? formatNumber(Math.round(entity.score / entity.userCount)) : 0}</TableCell>
                </TableRow>
            ));
        }

        return sortedStats.map((user) => (
            <TableRow key={user.id}>
                <TableCell>
                    <div style={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
                        <span>{`${user.givenName} ${user.familyName}`}</span>
                        {user.flag && <span>{user.flag}</span>}
                    </div>
                </TableCell>
                
                <TableCell>
                    {user.lkdUrl ? (
                        <a href={user.lkdUrl} target="_blank" rel="noopener noreferrer" style={{ display: 'flex', alignItems: 'center',justifyContent: 'center' }}>
                            <img src={linkedinIcon} alt="LinkedIn" style={{ width: '16px', height: '16px', }} />
                        </a>
                    ) : '-'}
                </TableCell>
                <TableCell>
                    <div 
                        style={{ 
                            width: '12px', 
                            height: '12px', 
                            borderRadius: '50%', 
                            backgroundColor: user.isActive ? '#4CAF50' : '#f44336',
                            margin: 'auto',
                        }} 
                        title={user.isActive ? 'Connected' : 'Disconnected'}
                    />
                </TableCell>
                <TableCell>{user.job || '-'}</TableCell>
                <TableCell>{user.location || '-'}</TableCell>
                <TableCell>{user.subscribers || 0}</TableCell>
                <TableCell>{user.subscribers > 0 ? `+${user.subscribersWon || 0}` : 0}</TableCell>
                <TableCell>{user.postsCount || 0}</TableCell>
                <TableCell>{formatNumber(user.totalViews || 0)}</TableCell>
                <TableCell>{user.postsCount ? formatNumber(Math.round(user.totalViews / user.postsCount)) : 0}</TableCell>
                <TableCell>{formatNumber(user.score || 0)}</TableCell>
            </TableRow>
        ));
    };

    const handleExportExcel = () => {
        let exportData;
        let columnWidths;

        if (viewType === 'entities') {
            // Export pour les entités
            exportData = sortedStats.map(entity => ({
                'Entity': entity.location,
                'Users': entity.userCount,
                'Total Posts': entity.postsCount,
                'Total Impressions': entity.totalViews,
                'Total Score': entity.score,
                'Moy. Impressions/User': entity.userCount > 0 ? Math.round(entity.totalViews / entity.userCount) : 0,
                'Moy. Score/User': entity.userCount > 0 ? Math.round(entity.score / entity.userCount) : 0
            }));

            columnWidths = [
                { wch: 20 },  // Entity
                { wch: 10 },  // Users
                { wch: 15 },  // Total Posts
                { wch: 20 },  // Total Impressions
                { wch: 15 },  // Total Score
                { wch: 20 },  // Moy. Impressions/User
                { wch: 20 }   // Moy. Score/User
            ];
        } else {
            // Export pour les utilisateurs
            exportData = sortedStats.map(user => ({
                'Name': `${user.givenName} ${user.familyName}`,
                'Job': user.job || '-',
                'Entity': user.location || '-',
                'Followers': user.subscribers || 0,
                'Followers Won': user.subscribers > 0 ? `+${user.subscribersWon || 0}` : 0,
                'Posts': user.postsCount || 0,
                'Impressions': user.totalViews || 0,
                'Moy. Impressions/Post': user.postsCount ? Math.round(user.totalViews / user.postsCount) : 0,
                'Total Score': user.score || 0
            }));

            columnWidths = [
                { wch: 25 },  // Name
                { wch: 20 },  // Job
                { wch: 15 },  // Entity
                { wch: 12 },  // Followers
                { wch: 15 },  // Followers Won
                { wch: 10 },  // Posts
                { wch: 15 },  // Impressions
                { wch: 20 },  // Moy. Impressions/Post
                { wch: 15 }   // Total Score
            ];
        }

        // Créer un nouveau workbook
        const wb = XLSX.utils.book_new();
        const ws = XLSX.utils.json_to_sheet(exportData);

        // Définir la largeur des colonnes
        ws['!cols'] = columnWidths;

        // Ajouter la feuille au workbook
        XLSX.utils.book_append_sheet(wb, ws, "Stats");

        // Générer le fichier Excel
        const fileName = `stats_${selectedYear}_${selectedMonth}_${viewType}.xlsx`;
        XLSX.writeFile(wb, fileName);
    };

    return (
        <div className="ranking base pt-3" style={{ flexDirection: 'column' }}>
            <h1 className="my-5" style={{marginLeft:"1.1em"}}>Stats</h1>
            <div style={{ padding: "0.5em 3em 0.5em 3em" }}>
                <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px' }}>
                    <div style={{ display: 'flex', gap: '10px' }}>
                        <DropdownDate
                            label="Month"
                            options={months}
                            selectedValue={selectedMonth}
                            onSelect={(value) => setSelectedMonth(value)}
                        />
                        <DropdownDate
                            label="Year"
                            options={years}
                            selectedValue={selectedYear}
                            onSelect={(value) => setSelectedYear(value)}
                        />
                        {viewType === 'users' && (
                            <DropdownDate
                                label="Entity"
                                options={entityOptions}
                                selectedValue={selectedEntity}
                                onSelect={(value) => setSelectedEntity(value)}
                            />
                        )}
                    </div>
                    <div style={{ display: 'flex', gap: '10px', alignItems: 'center' , height: '37px'}}>
                        <Button
                            variant="contained"
                            color="primary"
                            onClick={handleExportExcel}
                            style={{ height: '36px', minWidth: '36px', padding: '6px', backgroundColor: 'transparent', border: 'none', boxShadow: 'none' }}
                        >
                            <img src={exportExcelIcon} alt="Export Excel" style={{ width: '24px', height: '24px' }} />
                        </Button>
                        <ToggleButtonGroup
                            value={viewType}
                            exclusive
                            style={{ height: '37px' }}
                            onChange={handleViewTypeChange}
                            aria-label="view type"
                        >
                            <ToggleButton value="users" aria-label="users view">
                                Users
                            </ToggleButton>
                            <ToggleButton value="entities" aria-label="entities view">
                                Entities
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </div>
                </div>

                {loading ? (
                    <Box sx={{ display: 'flex', justifyContent: 'center', mt: 3 }}>
                        <CircularProgress />
                    </Box>
                ) : (
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                                {renderTableHeaders()}
                            </TableHead>
                            <TableBody>
                                {renderTableRows()}
                            </TableBody>
                        </Table>
                    </TableContainer>
                )}
            </div>
        </div>
    );
};

export default Stats;
