// components/Library.js

import {
    Button,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    TextField,
    Typography,
    Card,
    CardContent,
    CardMedia,
    Stack, 
    Grid,
    CircularProgress,
    Snackbar,
    Alert,
    Select,
    MenuItem,
    FormControl,
    InputLabel,
    FormHelperText,
    List,
    ListItem,
    ListItemText,
    Link
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import AddIcon from '@mui/icons-material/Add';
import './css/library.css';
import React, { useState, useEffect } from 'react';
import imgExpand from "../../img/expand.png";
import Iframe from 'react-iframe-click';
import useFileStore from '../../store/fileStore';

const truncateUrl = (url) => {
    if (url.length > 50) {
        return url.substring(0, 47) + '...';
    }
    return url;
};

const Library = () => {
    const {
        files,
        loading,
        error,
        selectedFile,
        showModal,
        showDeleteConfirm,
        fileToUpload,
        tag,
        newName,
        newRealCategory,
        newCategory,
        uploading,
        snackbar,
        errors,
        links,
        newLink,
        showLinksModal,
        fetchFiles,
        setSelectedFile,
        setShowModal,
        setShowDeleteConfirm,
        setFileToUpload,
        setTag,
        setNewName,
        setNewRealCategory,
        setNewCategory,
        setUploading,
        setSnackbar,
        setErrors,
        setLinks,
        setNewLink,
        setShowLinksModal,
        setSelectedCategory,
        selectedCategory,
        updateFileLinks,
    } = useFileStore();

    const apiUrl = process.env.REACT_APP_BACKEND_URL || 'http://localhost:3000';

    const [deleting, setDeleting] = useState(false);

    const languages = ['French', 'English', 'Spanish', 'Italian'];
    const realCategories = ['PAID','CONTENT', 'SEO', 'DATA', 'SOCIAL'];

    useEffect(() => {
        if (files.length === 0 && !loading && !error) {
            fetchFiles();
            setSelectedCategory('French');
        }
    }, []);

    const handleLanguageChange = (language) => {
        setSelectedFile(null);
        setSelectedCategory(language);
    };

    const handleFileSelect = (file) => {
        console.log("File selected:", file); // For debugging
        setSelectedFile(file);
    };

    const handleFileUpload = async () => {
        console.log('Uploading file...');
        
        if (!fileToUpload) {
            setSnackbar({
                open: true,
                message: 'Please select a file to upload!',
                severity: 'warning'
            });
            return;
        }

        if (!newCategory || !newRealCategory) {
            setSnackbar({
                open: true,
                message: 'Please select both a language and a category!',
                severity: 'warning'
            });
            return;
        }

        setUploading(true);

        const formData = new FormData();
        formData.append('file', fileToUpload);
        formData.append('tag', tag);
        formData.append('category', newCategory); // La langue va dans category
        formData.append('realCategory', newRealCategory); // La catégorie va dans realCategory
        formData.append('name', newName);

        console.log('File to upload:', fileToUpload);
        console.log('Form data entries:');
        for (let pair of formData.entries()) {
            console.log(pair[0], pair[1]);
        }

        try {
            const response = await fetch(`${apiUrl}/api/files/${encodeURIComponent(newRealCategory)}`, {
                method: 'POST',
                body: formData,
                headers: {
                    'Accept': 'application/json',
                },
            });

            console.log('Response status:', response.status);
            const responseText = await response.text();
            console.log('Response text:', responseText);

            if (response.ok) {
                console.log("File uploaded successfully");
                setSnackbar({
                    open: true,
                    message: 'File uploaded successfully!',
                    severity: 'success'
                });
                // Reset form
                setFileToUpload(null);
                setTag('');
                setNewName('');
                setNewCategory('');
                setNewRealCategory('');
                setShowModal(false);
                // Reload files
                fetchFiles();
            } else {
                const errorText = await response.text();
                console.error("File upload failed:", response.statusText, errorText);
                setSnackbar({
                    open: true,
                    message: 'File upload failed!',
                    severity: 'error'
                });
            }
        } catch (error) {
            console.error("Error uploading file:", error);
            setSnackbar({
                open: true,
                message: 'Error uploading file!',
                severity: 'error'
            });
        } finally {
            setUploading(false);
        }
    };
    

    const handleFileDelete = async () => {
        if (!selectedFile) {
            alert('Please select a file to delete!');
            return;
        }

        setShowDeleteConfirm(true);
    };

    const confirmDelete = async () => {
        setDeleting(true);
        try {
            const response = await fetch(`${apiUrl}/api/files/${selectedFile._id}`, {
                method: 'DELETE',
            });

            if (response.ok) {
                console.log("File deleted successfully");
                setSnackbar({
                    open: true,
                    message: 'File deleted successfully!',
                    severity: 'success'
                });
                fetchFiles();
                setSelectedFile(null);
            } else {
                const errorText = await response.text();
                console.error('Error deleting file:', response.statusText, errorText);
                setSnackbar({
                    open: true,
                    message: 'Error deleting file. Please try again.',
                    severity: 'error'
                });
            }
        } catch (error) {
            console.error('Error deleting file:', error);
            setSnackbar({
                open: true,
                message: 'Error deleting file. Please try again.',
                severity: 'error'
            });
        } finally {
            setDeleting(false);
            setShowDeleteConfirm(false);
        }
    };

    const getFileUrl = (file) => {
        const originalName = file.metadata.originalName || '';
        const lowerCaseName = originalName.toLowerCase();

        if (lowerCaseName.endsWith('.pdf')) {
            return `${apiUrl}/api/pdf/${encodeURIComponent(file.filename)}?t=${Date.now()}`;
        } else if (
            lowerCaseName.endsWith('.jpg') ||
            lowerCaseName.endsWith('.jpeg') ||
            lowerCaseName.endsWith('.png') ||
            lowerCaseName.endsWith('.gif')
        ) {
            return `${apiUrl}/api/image/${encodeURIComponent(file.filename)}?t=${Date.now()}`;
        } else if (lowerCaseName.endsWith('.mp4')) {
            return `${apiUrl}/api/video/${encodeURIComponent(file.filename)}?t=${Date.now()}`;
        } else {
            return `${apiUrl}/api/download/${file._id}`;
        }
    };

    const formatFileSize = (bytes) => {
        if (!bytes) return '0 Bytes';
        const k = 1024;
        const sizes = ['Bytes', 'KB', 'MB', 'GB'];
        const i = Math.floor(Math.log(bytes) / Math.log(k));
        return `${parseFloat((bytes / Math.pow(k, i)).toFixed(2))} ${sizes[i]}`;
    };

    const handleFileChange = (e) => {
        const file = e.target.files[0];
        if (file) {
            setFileToUpload(file);
            // Clear any previous file error
            setErrors(prev => ({ ...prev, file: false }));
        }
    };

    const renderFile = (file, previewMode) => {
        const originalName = file.metadata.originalName || 'Unknown';
        const filename = file.filename;
        const contentType = file.contentType || 'application/octet-stream';

        if (contentType.startsWith('image/')) {
            const imageUrl = `${apiUrl}/api/image/${encodeURIComponent(filename)}`;
            return (
                <div className="file-image-wrapper" >
                    <img 
                        src={imageUrl} 
                        alt={originalName} 
                        className="file-image"
                        loading="lazy"
                        onClick={() => setSelectedFile(file)}
                    />
                    {previewMode && (
                        <a
                            href={`${apiUrl}/api/download/${file._id}?download=true`}
                            download={originalName}
                            className="download-btn"
                            onClick={(e) => e.stopPropagation()}
                        >
                            Download
                        </a>
                    )}
                </div>
            );
        } else if (contentType === 'application/pdf') {
            const pdfUrl = `${apiUrl}/api/pdf/${encodeURIComponent(filename)}`;
            
            return (
                <div className="file-pdf">
                    <div className="pdf-preview" style={{ cursor: 'pointer', height: '100%' }} onClick={() => setSelectedFile(file)}>

                        <Iframe 
                            src={pdfUrl + '#toolbar=0'}
                            title="PDF Viewer"
                            key={`pdf-${file._id}`}
                            style={{ 
                                width: '100%',
                                height: !previewMode ? '100%' : '40em',
                                border: 'none',
                                overflow: 'auto'
                            }}
                            onClick={() => setSelectedFile(file)}
                            onInferredClick={() => handleFileSelect(file)}
                            
                            frameBorder="0"
                        />
                    </div>
                    {previewMode && (
                        <a
                            href={`${apiUrl}/api/download/${file._id}?download=true`}
                            download={originalName}
                            className="download-btn"
                            onClick={(e) => e.stopPropagation()}
                        >
                            Download
                        </a>
                    )}
                </div>
            );
        } else if (contentType === 'video/mp4') {
            return (
                <video 
                    crossOrigin={"anonymous"} 
                    style={{ maxWidth: '100%', height: '100%' }} 
                    controls={previewMode}
                    onClick={() => !previewMode && setSelectedFile(file)}
                >
                    <source src={`${apiUrl}/api/video/${encodeURIComponent(filename)}`} type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            );
        } else {
            return (
                <div className="file-download">
                    <a href={`${apiUrl}/api/download/${file._id}`} download={originalName} className="download-link">
                        Download
                    </a>
                </div>
            );
        }
    };

    const handleUpdateLinks = async () => {
        if (selectedFile) {
            console.log('Updating links for file:', selectedFile._id);
            console.log('Links to update:', links);
            await updateFileLinks(selectedFile._id, links);
        }
    };

    const handleAddLink = () => {
        if (newLink.trim()) {
            console.log('Adding new link:', newLink);
            setLinks([...links, newLink.trim()]);
            setNewLink('');
        }
    };

    const handleRemoveLink = (indexToRemove) => {
        console.log('Removing link at index:', indexToRemove);
        setLinks(links.filter((_, index) => index !== indexToRemove));
    };

    const openLinksModal = (file) => {
        console.log('Opening links modal for file:', file._id);
        setLinks(file.metadata.links || []);
        setShowLinksModal(true);
    };

    return (
        <div className="box">
            <Grid className='w-100 d-flex justify-between' sx={{justifyContent: "space-between",alignItems: "center",}}>
                <Button 
                    variant="contained" 
                    color="primary" 
                    startIcon={<AddIcon />} 
                    onClick={() => setShowModal(true)}
                >
                    Add File
                </Button>
                {
                    selectedFile &&
                        <Button 
                            variant="outlined" 
                            color="primary" 
                            startIcon={<AddIcon />} 
                            onClick={() => openLinksModal(selectedFile)} 
                            disabled={!selectedFile}
                        >
                            Manage Links
                        </Button>
                }
            </Grid>
            <div className="library-s">
                {/* Sidebar for languages */}
                <div className="sidebar-ly">
                    {languages.map((language, index) => (
                        <div
                            key={index}
                            className={`folder ${selectedCategory === language ? 'active' : ''}`}
                            onClick={() => handleLanguageChange(language)}
                        >
                            {language}
                        </div>
                    ))}
                </div>

                {/* File view */}
                <div className="file-view">
                    {Array.isArray(files) && files.length > 0 ? (
                        <div className="file-grid">
                            {files.map((file) => {
                                const originalName = file.metadata.originalName || '';
                                const lowerCaseName = originalName.toLowerCase();
                                const fileUrl = getFileUrl(file);

                                return (
                                    <div
                                        key={file._id}
                                        className={`file-item ${selectedFile && selectedFile._id === file._id ? 'selected' : ''}`}
                                        onClick={() => handleFileSelect(file)}
                                        
                                    >
                                        {renderFile(file)}
                                    </div>
                                );
                            })}
                        </div>
                    ) : (
                        <p>No files available</p>
                    )}
                </div>

                {/* Preview and controls */}
                <div className="contain-preview">
                    <div className="file-preview">
                        {selectedFile ? (
                            <Card sx={{ maxWidth: 345 }}>
                                <CardMedia>
                                    {renderFile(selectedFile, true)}
                                </CardMedia>
                                <CardContent>
                                    <Typography gutterBottom variant="h6" component="div">
                                        {selectedFile.metadata.name || 'No name'}
                                    </Typography>
                                    <Stack spacing={1}>
                                        <Typography variant="body2" color="text.secondary">
                                            <strong>Filename:</strong>{selectedFile.metadata.originalName || 'No name'}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            <strong>Category:</strong> {selectedFile.metadata.realCategory || 'No category'}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            <strong>Tag:</strong> {selectedFile.metadata.tag || 'No tag'}
                                        </Typography>
                                        <Typography variant="body2" color="text.secondary">
                                            <strong>Size:</strong> {formatFileSize(selectedFile.length)}
                                        </Typography>
                                        {selectedFile.metadata.links && selectedFile.metadata.links.length > 0 && (
                                            <>
                                                <Typography variant="body2" color="text.secondary">
                                                    <strong>Links:</strong>
                                                </Typography>
                                                <List dense sx={{ pl: 2, mt: 0 }}>
                                                    {selectedFile.metadata.links.map((link, index) => (
                                                        <ListItem key={index} disablePadding>
                                                            <ListItemText 
                                                                primary={
                                                                    <Link 
                                                                        href={link} 
                                                                        target="_blank" 
                                                                        rel="noopener noreferrer"
                                                                        sx={{ 
                                                                            color: 'text.secondary',
                                                                            textDecoration: 'none',
                                                                            '&:hover': {
                                                                                textDecoration: 'underline'
                                                                            }
                                                                        }}
                                                                    >
                                                                        {truncateUrl(link)}
                                                                    </Link>
                                                                }
                                                            />
                                                        </ListItem>
                                                    ))}
                                                </List>
                                            </>
                                        )}
                                    </Stack>
                                    {selectedFile && (
                                        <Button 
                                            variant="outlined" 
                                            color="error" 
                                            startIcon={<DeleteIcon />} 
                                            onClick={handleFileDelete}
                                            fullWidth
                                            sx={{ mt: 2 }}
                                        >
                                            Delete
                                        </Button>
                                    )}
                                </CardContent>
                            </Card>
                        ) : (
                            <Typography variant="body2" color="text.secondary">No file selected</Typography>
                        )}
                    </div>
                </div>
            </div>

            {/* Delete Confirmation Dialog */}
            <Dialog
                open={showDeleteConfirm}
                onClose={() => setShowDeleteConfirm(false)}
            >
                <DialogTitle>Confirm Delete</DialogTitle>
                <DialogContent>
                    <Typography>
                        Are you sure you want to delete "{selectedFile?.metadata?.originalName}"?
                        This action cannot be undone.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => setShowDeleteConfirm(false)}>
                        Cancel
                    </Button>
                    <Button 
                        onClick={confirmDelete} 
                        color="error"
                        variant="contained"
                        disabled={deleting}
                    >
                        {deleting ? <CircularProgress size={24} /> : 'Delete'}
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Upload Modal */}
            <Dialog open={showModal} onClose={() => setShowModal(false)}>
                <DialogTitle>Upload a File</DialogTitle>
                <DialogContent>
                    <Stack spacing={2} sx={{ mt: 2 }}>
                        <input
                            type="file"
                            onChange={(e) => setFileToUpload(e.target.files[0])}
                            style={{ marginTop: '10px' }}
                        />
                        <TextField
                            fullWidth
                            label="Name"
                            value={newName}
                            onChange={(e) => setNewName(e.target.value)}
                            error={errors.name}
                            helperText={errors.name}
                        />
                        <FormControl fullWidth>
                            <InputLabel>Language</InputLabel>
                            <Select
                                value={newCategory}
                                onChange={(e) => setNewCategory(e.target.value)}
                                label="Language"
                            >
                                {languages.map((lang) => (
                                    <MenuItem key={lang} value={lang}>
                                        {lang}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <FormControl fullWidth>
                            <InputLabel>Category</InputLabel>
                            <Select
                                value={newRealCategory}
                                onChange={(e) => setNewRealCategory(e.target.value)}
                                label="Category"
                            >
                                {realCategories.map((category) => (
                                    <MenuItem key={category} value={category}>
                                        {category}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <TextField
                            fullWidth
                            label="Tag"
                            value={tag}
                            onChange={(e) => setTag(e.target.value)}
                            error={errors.tag}
                            helperText={errors.tag}
                        />
                    </Stack>
                </DialogContent>
                <DialogActions sx={{ p: 2, position: 'relative' }}>
                    {uploading && (
                        <CircularProgress
                            size={24}
                            sx={{
                                position: 'absolute',
                                left: '50%',
                                marginLeft: '-12px'
                            }}
                        />
                    )}
                    <Button onClick={() => setShowModal(false)}>Cancel</Button>
                    <Button onClick={handleFileUpload} disabled={uploading}>
                        Upload
                    </Button>
                </DialogActions>
            </Dialog>

            {/* Modal pour gérer les liens */}
            <Dialog 
                open={showLinksModal} 
                onClose={() => setShowLinksModal(false)}
                maxWidth="md"
                fullWidth
                PaperProps={{
                    sx: {
                        borderRadius: '12px',
                        padding: '16px',
                        minHeight: '500px'
                    }
                }}
            >
                <DialogTitle sx={{ 
                    fontSize: '24px', 
                    fontWeight: 500,
                    borderBottom: '1px solid #eee',
                    pb: 2
                }}>
                    Manage Associated Links
                </DialogTitle>
                <DialogContent sx={{ mt: 2 }}>
                    <Stack spacing={3}>
                        <div>
                            <Typography variant="subtitle1" sx={{ mb: 1, color: '#666' }}>
                                Add a new link for {selectedFile?.metadata?.name}
                            </Typography>
                            <Stack direction="row" spacing={2} alignItems="flex-start">
                                <TextField
                                    fullWidth
                                    label="Link URL"
                                    value={newLink}
                                    onChange={(e) => setNewLink(e.target.value)}
                                    onKeyPress={(e) => e.key === 'Enter' && handleAddLink()}
                                    placeholder="https://..."
                                    sx={{
                                        '& .MuiOutlinedInput-root': {
                                            borderRadius: '8px'
                                        }
                                    }}
                                />
                                <Button
                                    variant="contained"
                                    onClick={handleAddLink}
                                    startIcon={<AddIcon />}
                                    sx={{
                                        borderRadius: '8px',
                                        height: '56px',
                                        minWidth: '140px'
                                    }}
                                >
                                    Add Link
                                </Button>
                            </Stack>
                        </div>

                        <div>
                            <Typography variant="subtitle1" sx={{ mb: 2, color: '#666' }}>
                                Existing Links
                            </Typography>
                            <Stack spacing={2}>
                                {links.length === 0 ? (
                                    <Typography variant="body1" sx={{ color: '#999', textAlign: 'center', py: 4 }}>
                                        No associated links
                                    </Typography>
                                ) : (
                                    links.map((link, index) => (
                                        <Stack
                                            key={index}
                                            direction="row"
                                            spacing={2}
                                            alignItems="center"
                                            sx={{
                                                bgcolor: '#f8f9fa',
                                                borderRadius: '8px',
                                                p: 2
                                            }}
                                        >
                                            <Typography 
                                                sx={{ 
                                                    flex: 1,
                                                    wordBreak: 'break-all',
                                                    color: '#2196F3',
                                                    '&:hover': {
                                                        textDecoration: 'underline',
                                                        cursor: 'pointer'
                                                    }
                                                }}
                                                onClick={() => window.open(link, '_blank')}
                                            >
                                                {link}
                                            </Typography>
                                            <Button
                                                variant="outlined"
                                                color="error"
                                                onClick={() => handleRemoveLink(index)}
                                                size="small"
                                                sx={{
                                                    minWidth: 'auto',
                                                    width: '40px',
                                                    height: '40px',
                                                    borderRadius: '8px'
                                                }}
                                            >
                                                <DeleteIcon />
                                            </Button>
                                        </Stack>
                                    ))
                                )}
                            </Stack>
                        </div>
                    </Stack>
                </DialogContent>
                <DialogActions sx={{ 
                    borderTop: '1px solid #eee',
                    pt: 2,
                    px: 3
                }}>
                    <Button 
                        onClick={() => setShowLinksModal(false)}
                        sx={{
                            borderRadius: '8px',
                            minWidth: '100px'
                        }}
                    >
                        Cancel
                    </Button>
                    <Button 
                        onClick={handleUpdateLinks} 
                        variant="contained"
                        sx={{
                            borderRadius: '8px',
                            minWidth: '100px'
                        }}
                    >
                        Save
                    </Button>
                </DialogActions>
            </Dialog>

            <Snackbar
                open={snackbar.open}
                autoHideDuration={6000}
                onClose={() => setSnackbar(prev => ({ ...prev, open: false }))}
            >
                <Alert 
                    onClose={() => setSnackbar(prev => ({ ...prev, open: false }))} 
                    severity={snackbar.severity}
                    sx={{ width: '100%' }}
                >
                    {snackbar.message}
                </Alert>
            </Snackbar>
        </div>
    );
};

export default Library;