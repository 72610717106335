import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { 
    TextField, 
    Button, 
    Container, 
    Typography, 
    Box, 
    CircularProgress, 
    Snackbar, 
    Tooltip, 
    RadioGroup, 
    FormControlLabel, 
    Radio 
} from '@mui/material';
import StarIcon from '@mui/icons-material/AutoAwesome';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const apiUrl = process.env.REACT_APP_BACKEND_URL;

// Expression régulière pour valider les liens LinkedIn
const linkedInUrlRegex = /^https:\/\/www\.linkedin\.com/;

// Configuration des modules Quill
const modules = {
    toolbar: false // Désactive la barre d'outils
};

const formats = [
    'header',
    'bold', 'italic', 'underline', 'strike',
    'list', 'bullet',
    'link'
];

const Reformer = () => {
    const [subject, setSubject] = useState('');
    const [postUrl, setPostUrl] = useState('');
    const [generatedText, setGeneratedText] = useState('');
    const [editableText, setEditableText] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [copySuccess, setCopySuccess] = useState(false);
    const [urlError, setUrlError] = useState(false);
    const [showMore, setShowMore] = useState(false);
    const [postLength, setPostLength] = useState('standard');
    const [textFieldHeight, setTextFieldHeight] = useState('auto');

    // Gestion du redimensionnement du TextField
    useEffect(() => {
        if (editableText) {
            const lines = editableText.split('\n').length;
            const minHeight = Math.max(100, Math.min(lines * 24, 400)); // 24px par ligne, min 100px, max 400px
            setTextFieldHeight(`${minHeight}px`);
        } else {
            setTextFieldHeight('auto');
        }
    }, [editableText]);

    const handleGenerate = async () => {
        if (!postUrl || !linkedInUrlRegex.test(postUrl)) {
            setUrlError(true);
            return;
        }

        setUrlError(false);
        setIsLoading(true);
        try {
            const response = await axios.post(`${apiUrl}/api/generatePost`, {
                subject,
                postUrl,
                length: postLength,
            });
            const generated = response.data.generatedText;
            setGeneratedText(generated);
            setEditableText(generated);
        } catch (error) {
            console.error('Error generating post:', error);
        } finally {
            setIsLoading(false);
        }
    };

    const handlePostUrlChange = (e) => {
        setPostUrl(e.target.value);
        if (linkedInUrlRegex.test(e.target.value)) {
            setUrlError(false);
        }
    };

    const handleCopy = () => {
        navigator.clipboard.writeText(editableText);
        setCopySuccess(true);
        setTimeout(() => setCopySuccess(false), 2000);
    };

    return (
        <div className="base">
            <Container className='base' maxWidth="sm" sx={{ padding: 2 }}>
                <Box className='Feed' sx={{ backgroundColor: 'white', padding: 3, borderRadius: 2, boxShadow: 3 }}>
                    <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center', marginBottom: 2 }}>
                        <Typography variant="h4" gutterBottom align="center">
                            Post Reformer
                        </Typography>
                        <Tooltip 
                            title="Generate a LinkedIn post based on the subject and URL using our AI tools." 
                            arrow
                        >
                            <HelpOutlineIcon 
                                sx={{ marginLeft: 1, marginBottom:1, cursor: 'pointer', fontSize: '1em', color: 'grey' }} 
                            />
                        </Tooltip>
                    </Box>
                    
                    <TextField
                        label="Subject"
                        variant="outlined"
                        fullWidth
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                        sx={{ marginBottom: 2 }}
                    />
                    
                    <TextField
                        label="LinkedIn Post URL"
                        variant="outlined"
                        fullWidth
                        value={postUrl}
                        onChange={handlePostUrlChange}
                        error={urlError}
                        helperText={
                            urlError
                                ? 'Please enter a valid LinkedIn post URL (e.g., https://www.linkedin.com/analytics/post-summary/urn:li:activity:1234567890/)'
                                : ''
                        }
                        sx={{ marginBottom: 2 }}
                    />
                    
                    <Button
                        variant="text"
                        onClick={() => setShowMore(!showMore)}
                        sx={{ marginBottom: 2 }}
                    >
                        {showMore ? 'Hide Options' : 'More Options'}
                    </Button>

                    {showMore && (
                        <Box sx={{ marginBottom: 2 }}>
                            <Typography variant="subtitle1" sx={{ marginBottom: 1 }}>
                                Choose Post Length:
                            </Typography>
                            <RadioGroup
                                row
                                value={postLength}
                                onChange={(e) => setPostLength(e.target.value)}
                                sx={{
                                    justifyContent: "center",
                                    "& .MuiFormControlLabel-root": {
                                        margin: "0 10px",
                                    },
                                }}
                            >
                                <FormControlLabel
                                    value="short"
                                    control={<Radio sx={{ color: "#2575fc" }} />}
                                    label="Short"
                                    sx={{
                                        "& .MuiTypography-root": { color: "#333" },
                                    }}
                                />
                                <FormControlLabel
                                    value="standard"
                                    control={<Radio sx={{ color: "#2575fc" }} />}
                                    label="Standard"
                                    sx={{
                                        "& .MuiTypography-root": { color: "#333" },
                                    }}
                                />
                                <FormControlLabel
                                    value="long"
                                    control={<Radio sx={{ color: "#2575fc" }} />}
                                    label="Long"
                                    sx={{
                                        "& .MuiTypography-root": { color: "#333" },
                                    }}
                                />
                            </RadioGroup>
                        </Box>
                    )}

                    <Button
                        variant="contained"
                        onClick={handleGenerate}
                        disabled={isLoading}
                        sx={{
                            marginTop: 1,
                            marginBottom: 1,
                            padding: '10px 20px',
                            position: 'relative',

                            color: 'white',
                            fontWeight: 'bold',
                            fontSize: '20px',
                            textTransform: 'none',
                            borderRadius: '30px',
                            display: 'flex',
                            justifyContent: 'center',
                            alignItems: 'center',
                            boxShadow: '0 4px 10px rgba(37, 117, 252, 0.2)',
                            transition: 'all 0.6s cubic-bezier(0.4, 0, 0.2, 1)',
                            overflow: 'hidden',
                            minWidth: '250px',
                            minHeight: '50px',
                            background: 'linear-gradient(45deg, #51cbf3 30%, #2575fc 90%)',
                            '& .button-content': {
                                position: 'relative',
                                zIndex: 3,
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                width: '100%',
                            },
                            '&:before': {
                                content: '""',
                                position: 'absolute',
                                top: 0,
                                left: '-100%',
                                width: '100%',
                                height: '100%',
                                background: 'linear-gradient(120deg, transparent, rgba(255,255,255,0.3), transparent)',
                                animation: 'shine 3s infinite linear',
                                zIndex: 2,
                            },
                            '&:after': {
                                content: '""',
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                right: 0,
                                bottom: 0,
                                background: 'linear-gradient(45deg, #2575fc 30%, #51cbf3 90%)',
                                opacity: 0,
                                transition: 'opacity 0.8s ease-in-out',
                                zIndex: 1,
                            },
                            '@keyframes shine': {
                                '0%': {
                                    left: '-100%',
                                },
                                '100%': {
                                    left: '100%',
                                },
                            },
                            '&:hover': {
                                transform: 'scale(1.03) translateY(-2px)',
                                boxShadow: '0 8px 20px rgba(37, 117, 252, 0.3)',
                                '&:after': {
                                    opacity: 1,
                                },
                            },
                            '&:active': {
                                transform: 'scale(0.98)',
                                boxShadow: '0 2px 5px rgba(37, 117, 252, 0.2)',
                            },
                            '&.Mui-disabled': {
                                background: 'linear-gradient(45deg, #ccc 30%, #eee 90%)',
                                boxShadow: 'none',
                                '&:after': {
                                    display: 'none',
                                },
                            },
                        }}
                    >
                        <Box className="button-content">
                            {isLoading ? (
                                <CircularProgress 
                                    size={24} 
                                    sx={{ 
                                        color: 'white',
                                    }} 
                                />
                            ) : (
                                <>
                                    GENERATE A POST
                                    <StarIcon 
                                        sx={{
                                            marginLeft: '8px',
                                            transition: 'transform 0.6s ease',
                                            animation: isLoading ? 'spin 2s infinite linear' : 'none',
                                            '@keyframes spin': {
                                                '0%': {
                                                    transform: 'rotate(0deg)',
                                                },
                                                '100%': {
                                                    transform: 'rotate(360deg)',
                                                },
                                            },
                                        }}
                                    />
                                </>
                            )}
                        </Box>
                    </Button>
                    


                    {generatedText && !isLoading && (
                        <>
                            <Box sx={{ 
                                marginTop: 2, 
                                marginBottom: 2,
                                width: '100%',
                                '& .ql-container': {
                                    minHeight: '200px',
                                    fontSize: '1rem',
                                    fontFamily: 'inherit',
                                    border: '1px solid #e0e0e0',
                                    borderRadius: '4px',
                                },
                                '& .ql-editor': {
                                    minHeight: '200px',
                                    padding: '12px 15px',
                                },
                                '& .ql-editor:focus': {
                                    boxShadow: '0 0 0 2px rgba(37, 117, 252, 0.2)',
                                },
                            }}>
                                <ReactQuill
                                    value={editableText}
                                    onChange={setEditableText}
                                    modules={modules}
                                    formats={formats}
                                    placeholder="Your generated text will appear here..."
                                    theme="snow"
                                />
                            </Box>
                            
                            <Button
                                variant="outlined"
                                color="primary"
                                onClick={handleCopy}
                                sx={{ width: '100%' }}
                            >
                                Copy
                            </Button>
                        </>
                    )}
                </Box>
            </Container>

            <Snackbar
                open={copySuccess}
                autoHideDuration={2000}
                onClose={() => setCopySuccess(false)}
                message="Text copied to clipboard!"
            />
        </div>
    );
};

export default Reformer;
