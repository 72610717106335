import React, { useState, useEffect } from 'react';
import axios from 'axios';
const apiUrl = process.env.REACT_APP_BACKEND_URL;

const Popup = () => {
    const [showPopup, setShowPopup] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [error, setError] = useState('');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const [userId, setUserId] = useState(null);

    useEffect(() => {
        checkLinkedInCredentials();
    }, []);

    const checkLinkedInCredentials = async () => {
        try {
            // Première requête pour obtenir l'ID de l'utilisateur
            const userResponse = await axios.get(`${apiUrl}/api/extensions`, {
                withCredentials: true
            });

            if (!userResponse.data || !userResponse.data.id) {
                console.error('No user ID found');
                setShowPopup(true);
                return;
            }

            setUserId(userResponse.data.id);

            // Vérifier les identifiants LinkedIn pour cet utilisateur
            const linkedInResponse = await axios.get(`${apiUrl}/api/linkedin/check-credentials/${userResponse.data.id}`, {
                withCredentials: true
            });

            const { hasCredentials, isValid } = linkedInResponse.data;

            // Afficher la popup si :
            // - Pas d'identifiants trouvés (hasCredentials: false)
            // - Identifiants invalides ou expirés (isValid: false)
            if (!hasCredentials || !isValid) {
                setShowPopup(true);
            }
        } catch (error) {
            console.error('Error checking LinkedIn credentials:', error);
            setShowPopup(true);
        } finally {
            setIsLoading(false);
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setError('');
        setIsSubmitting(true);
        
        try {
            const response = await axios.post(`${apiUrl}/api/linkedin/login`, {
                userId,
                email,
                password
            }, {
                withCredentials: true
            });

            if (response.data.success) {
                setShowPopup(false);
            } else {
                setError(response.data.message || 'Invalid credentials');
            }
        } catch (error) {
            setError('Connection error. Please try again.');
            console.error('Login error:', error);
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <div>
            {!isLoading && showPopup && (
                <div className="popup">
                    <div className="popup-content">
                        <h2>LinkedIn Login Required</h2>
                        <p className="description">
                            To analyze your LinkedIn statistics and optimize your activity, 
                            we need your login credentials. Your information is secure and 
                            is only used to access your LinkedIn data.
                        </p>
                        
                        <form onSubmit={handleSubmit} className="login-form">
                            <div className="form-group">
                                <label htmlFor="email">LinkedIn Email</label>
                                <input
                                    type="email"
                                    id="email"
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    placeholder="your@email.com"
                                    required
                                    title="Please enter your LinkedIn email address"
                                    onInvalid={e => e.target.setCustomValidity('Please enter your LinkedIn email address')}
                                    onInput={e => e.target.setCustomValidity('')}
                                />
                            </div>
                            
                            <div className="form-group">
                                <label htmlFor="password">LinkedIn Password</label>
                                <input
                                    type="password"
                                    id="password"
                                    value={password}
                                    onChange={(e) => setPassword(e.target.value)}
                                    placeholder="Your password"
                                    required
                                    title="Please enter your LinkedIn password"
                                    onInvalid={e => e.target.setCustomValidity('Please enter your LinkedIn password')}
                                    onInput={e => e.target.setCustomValidity('')}
                                />
                            </div>
                            
                            <button 
                                type="submit" 
                                className={`submit-button ${isSubmitting ? 'submitting' : ''}`}
                                disabled={isSubmitting}
                            >
                                {isSubmitting ? (
                                    <span className="button-content">
                                        <span className="spinner"></span>
                                        Signing in...
                                    </span>
                                ) : (
                                    <span className="button-content">Sign In</span>
                                )}
                            </button>

                            {error && <p className="error-message">{error}</p>}
                        </form>
                    </div>
                </div>
            )}

            <style>{`
                .popup {
                    position: fixed;
                    left: 0;
                    top: 0;
                    width: 100%;
                    height: 100%;
                    background: rgba(0, 0, 0, 0.5);
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    z-index: 9999;
                }
                .popup-content {
                    background: white;
                    padding: 30px;
                    border-radius: 8px;
                    width: 400px;
                    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
                }
                .popup h2 {
                    color: #0a66c2;
                    margin-bottom: 15px;
                    font-size: 24px;
                }
                .description {
                    color: #666;
                    margin-bottom: 25px;
                    line-height: 1.5;
                    font-size: 14px;
                }
                .login-form {
                    display: flex;
                    flex-direction: column;
                    gap: 20px;
                }
                .form-group {
                    display: flex;
                    flex-direction: column;
                    gap: 8px;
                }
                .popup label {
                    color: #333;
                    font-size: 14px;
                    font-weight: 500;
                }
                .popup input {
                    padding: 10px;
                    border: 1px solid #ddd;
                    border-radius: 4px;
                    font-size: 14px;
                }
                .popup input:focus {
                    border-color: #0a66c2;
                    outline: none;
                    box-shadow: 0 0 0 2px rgba(10, 102, 194, 0.1);
                }
                .submit-button {
                    background: #0a66c2;
                    color: white;
                    padding: 12px;
                    border: none;
                    border-radius: 4px;
                    font-size: 16px;
                    font-weight: 600;
                    cursor: pointer;
                    transition: all 0.2s ease;
                    position: relative;
                    overflow: hidden;
                }
                .submit-button:hover {
                    background: #004182;
                }
                .submit-button:disabled {
                    background: #0a66c2;
                    opacity: 0.7;
                    cursor: not-allowed;
                }
                .button-content {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    gap: 8px;
                }
                .spinner {
                    width: 16px;
                    height: 16px;
                    border: 2px solid #ffffff;
                    border-top: 2px solid transparent;
                    border-radius: 50%;
                    animation: spin 0.8s linear infinite;
                }
                @keyframes spin {
                    0% { transform: rotate(0deg); }
                    100% { transform: rotate(360deg); }
                }
                .error-message {
                    color: #d93025;
                    font-size: 14px;
                    text-align: center;
                    padding: 8px;
                    background: rgba(217, 48, 37, 0.1);
                    border-radius: 4px;
                    margin-top: 10px;
                }
            `}</style>
        </div>
    );
};

export default Popup;
